[data-simplebar] {
  flex-flow: column wrap;
  place-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.simplebar-wrapper {
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
  overflow: hidden;
}

.simplebar-mask {
  direction: inherit;
  z-index: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
  overflow: hidden;
  width: auto !important;
  height: auto !important;
}

.simplebar-offset {
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
}

.simplebar-content-wrapper {
  direction: inherit;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: auto;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  display: block;
  position: relative;
  box-sizing: border-box !important;
}

.simplebar-content-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.simplebar-content:after, .simplebar-content:before {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  pointer-events: none;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.simplebar-height-auto-observer-wrapper {
  float: left;
  z-index: -1;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
  width: 100%;
  max-width: 1px;
  height: 100%;
  max-height: 1px;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  box-sizing: inherit !important;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  width: 1000%;
  min-width: 1px;
  height: 1000%;
  min-height: 1px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.simplebar-track {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  min-height: 10px;
  position: absolute;
  left: 0;
  right: 0;
}

.simplebar-scrollbar:before {
  content: "";
  opacity: 0;
  background: #000;
  border-radius: 7px;
  transition: opacity .2s linear;
  position: absolute;
  left: 2px;
  right: 2px;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: .5;
  transition: opacity linear;
}

.simplebar-track.simplebar-vertical {
  width: 11px;
  top: 0;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  height: 11px;
  left: 0;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  width: auto;
  min-width: 10px;
  height: 7px;
  min-height: 0;
  top: 2px;
  left: 0;
  right: auto;
}

[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  left: 0;
  right: auto;
}

.hs-dummy-scrollbar-size {
  opacity: 0;
  visibility: hidden;
  direction: rtl;
  width: 500px;
  height: 500px;
  position: fixed;
  overflow: scroll hidden;
}

.simplebar-hide-scrollbar {
  visibility: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: fixed;
  left: 0;
  overflow-y: scroll;
}

.rc-slider {
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: #0000;
  border-radius: 6px;
  width: 100%;
  height: 14px;
  padding: 5px 0;
  position: relative;
}

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: #0000;
}

.rc-slider-rail {
  background-color: #e9e9e9;
  border-radius: 6px;
  width: 100%;
  height: 4px;
  position: absolute;
}

.rc-slider-track {
  background-color: #abe2fb;
  border-radius: 6px;
  height: 4px;
  position: absolute;
  left: 0;
}

.rc-slider-handle {
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  touch-action: pan-x;
  background-color: #fff;
  border: 2px solid #96dbfa;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  position: absolute;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
}

.rc-slider-handle:focus {
  outline: none;
}

.rc-slider-handle-click-focused:focus {
  box-shadow: unset;
  border-color: #96dbfa;
}

.rc-slider-handle:hover {
  border-color: #57c5f7;
}

.rc-slider-handle:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
}

.rc-slider-mark {
  width: 100%;
  font-size: 12px;
  position: absolute;
  top: 18px;
  left: 0;
}

.rc-slider-mark-text {
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
  display: inline-block;
  position: absolute;
}

.rc-slider-mark-text-active {
  color: #666;
}

.rc-slider-step {
  background: none;
  width: 100%;
  height: 4px;
  position: absolute;
}

.rc-slider-dot {
  cursor: pointer;
  vertical-align: middle;
  background-color: #fff;
  border: 2px solid #e9e9e9;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  position: absolute;
  bottom: -2px;
}

.rc-slider-dot-active {
  border-color: #96dbfa;
}

.rc-slider-dot-reverse {
  margin-right: -4px;
}

.rc-slider-disabled {
  background-color: #e9e9e9;
}

.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}

.rc-slider-disabled .rc-slider-handle, .rc-slider-disabled .rc-slider-dot {
  box-shadow: none;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ccc;
}

.rc-slider-disabled .rc-slider-mark-text, .rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}

.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}

.rc-slider-vertical .rc-slider-rail {
  width: 4px;
  height: 100%;
}

.rc-slider-vertical .rc-slider-track {
  width: 4px;
  bottom: 0;
  left: 5px;
}

.rc-slider-vertical .rc-slider-handle {
  touch-action: pan-y;
  margin-left: -5px;
}

.rc-slider-vertical .rc-slider-mark {
  height: 100%;
  top: 0;
  left: 18px;
}

.rc-slider-vertical .rc-slider-step {
  width: 4px;
  height: 100%;
}

.rc-slider-vertical .rc-slider-dot {
  margin-bottom: -4px;
  left: 2px;
}

.rc-slider-vertical .rc-slider-dot:first-child, .rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}

.rc-slider-tooltip-zoom-down-enter, .rc-slider-tooltip-zoom-down-appear, .rc-slider-tooltip-zoom-down-leave {
  animation-duration: .3s;
  animation-play-state: paused;
  animation-fill-mode: both;
  display: block !important;
}

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active, .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-enter, .rc-slider-tooltip-zoom-down-appear {
  animation-timing-function: cubic-bezier(.23, 1, .32, 1);
  transform: scale(0);
}

.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(.755, .05, .855, .06);
}

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0);
  }

  100% {
    transform-origin: 50% 100%;
    transform: scale(1);
  }
}

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0);
  }
}

.rc-slider-tooltip {
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: #0000;
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: #0000;
}

.rc-slider-tooltip-hidden {
  display: none;
}

.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px;
}

.rc-slider-tooltip-inner {
  color: #fff;
  text-align: center;
  background-color: #6c6c6c;
  border-radius: 6px;
  min-width: 24px;
  height: 24px;
  padding: 6px 2px;
  font-size: 12px;
  line-height: 1;
  text-decoration: none;
  box-shadow: 0 0 4px #d9d9d9;
}

.rc-slider-tooltip-arrow {
  border-style: solid;
  border-color: #0000;
  width: 0;
  height: 0;
  position: absolute;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
  margin-left: -4px;
  bottom: 4px;
  left: 50%;
}
/*# sourceMappingURL=index.cb49c6c0.css.map */
